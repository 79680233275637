<!-- <div id="jitsi-iframe"></div> -->

<h1 style="text-align: center;">This is testing screen</h1>

<label for="roomName">Enter Room Name:</label>
<input type="text" id="roomName" [(ngModel)]="room">

<button (click)="handleNewMeet()" style="margin: 2rem;">Call</button>
<button (click)="handleNewMeetWithRecording()" style="margin: 2rem;">Call with Recording</button>

<button (click)="handleLeaveCall()" style="margin: 2rem;">Leave Call (Leave the meeting)</button>
<button (click)="handleEndCall()" style="margin: 2rem;">End Call (End the meeting for Everyone)</button>
<button (click)="handleStartRecording()" style="margin: 2rem;">Start Recording</button>
<button (click)="handle()" style="margin: 2rem;">Get Participants</button>
<button (click)="handleIframe()" style="margin: 2rem;">Iframe</button>

<div class="item-center">
    <!-- <span>Custom Controls</span> -->
</div>
<!-- <div class="item-center">
    <span>&nbsp;&nbsp;</span>
    <i (click)="executeCommand('toggleAudio')" class="fas fa-2x grey-color" [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true" title="Mute / Unmute"></i>
    <i (click)="executeCommand('isVideoMuted')" class="fas fa-phone-slash fa-2x red-color" aria-hidden="true" title="Leave"></i>
    <i (click)="executeCommand('toggleVideo')" class="fas fa-2x grey-color" [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true" title="Start / Stop camera"></i>
    <i (click)="executeCommand('toggleShareScreen')" class="fas fa-film fa-2x grey-color" aria-hidden="true" title="Share your screen"></i>
</div> -->

<!-- <button (click)="openModal()">Open Modal</button> -->

<div class="modal" [ngClass]="{ 'is-active': showModal }">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div style="display: flex; gap: 1rem;">
        <div id="jitsi-iframe"></div>
        <div style="background-color: black; display: flex; justify-content: center; align-items: center;">
          <video style="margin: 8px; width: 500px; height: 270px; border-radius: 6px;" #localVideo autoplay></video>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeModal()">Close</button>
  </div>
</div>
